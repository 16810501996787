import { broadcastQuery$ } from './intents'
import { flog } from '@zambezi/caballo-vivo'
import { functions$, db$ } from './core'

import { httpsCallable } from 'firebase/functions'
import { setDoc, doc, Timestamp, onSnapshot } from 'firebase/firestore'

import {
  map,
  startWith,
  withLatestFrom,
  switchMap,
  filter,
  tap,
  mapTo,
} from 'rxjs/operators'
import { from, concat, Observable } from 'rxjs'
import { withLoader } from './loader'
import location$ from './location'

export const queryBroadcast$ = broadcastQuery$.pipe(
  withLatestFrom(functions$),
  switchMap(([{ query, id }, functions]) => {
    const sendQuery = httpsCallable(functions, 'submitQuery')
    return from(sendQuery({ query, id })).pipe(
      flog('Send query response'),
      tap(({data}) => console.info('PROMPT WITH CONTEXT:\n\n', data.promptWithContext)),
      mapTo(''),
      withLoader('Loading conversation')
    )
  }),
  startWith("What's VRP? BTW, who are you?"),
  flog('Query')
)

export const conversation$ = location$.pipe(
  map(({ pathname }) => {
    const id = pathToConversationId(pathname) || createId()
    const newPath = `/conversations/${id}`
    if (pathname !== newPath) window.history.pushState({}, '', newPath)
    return id
  }),
  withLatestFrom(db$),
  flog('Conversations -- location'),
  switchMap(([id, db]) => {
    return concat(
      setDoc(
        doc(db, 'conversations', id),
        {
          lastInteracted: Timestamp.fromDate(new Date())
        },
        { merge: true }
      ),
      updatesForConversation$(db, id)
    )
  }),
  filter((d) => !!d),
  map(d => ({id:d.id, ...d.data()})),
  tap(({chatHistory}) => { if (chatHistory)  console.info(chatHistory)}),
  flog('Conversations - after construction')
)

function pathToConversationId(path) {
  const match = path.match(/\/conversations\/(.*)$/)
  return match && match[1]
}

function createId() {
  return `${new Date().toISOString().replace(/[^\d]/g, '')}-${Math.floor(
    Math.random() * 0xffffffff
  )
    .toString(32)
    .toUpperCase()}`
}

function updatesForConversation$(db, id) {
  return new Observable((p) => {
    return onSnapshot(doc(db, 'conversations', id), (doc) => p.next(doc))
  })
}
