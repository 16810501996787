import {map} from 'rxjs/operators'

export default function stow(location) {
  return (s$) =>
    s$.pipe(
      map((n) => (state) => {
        return {...state, [location]: n}
      }),
    )
}
