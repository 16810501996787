import {Subject, Observable} from 'rxjs'
import {tap, scan, map} from 'rxjs/operators'

const loader$ = new Subject().pipe(
  scan(updateLoader, new Set()),
  map((s) => (s.size ? Array.from(s.values()).join(' — ') : '')),
)
export default loader$

export function withLoader(label) {
  return function (source$) {
    return new Observable((o) => {
      loader$.next({add: label})
      source$
        .pipe(tap({complete: () => loader$.next({remove: label})}))
        .subscribe(o)
    })
  }
}

function updateLoader(acc, {add, remove}) {
  if (add) acc.add(add)
  if (remove) acc.delete(remove)
  return acc
}
