import {fromEvent} from 'rxjs'
import {startWith, map} from 'rxjs/operators'
import {flog, shareLast} from '@zambezi/caballo-vivo'

const location$ = fromEvent(window, 'popstate').pipe(
  map(() => window.location),
  startWith(window.location),
  flog('Location'), 
  shareLast
)

export default location$
