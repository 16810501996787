import React from 'react'
import ReactDOM from 'react-dom/client'
import {Subject} from 'rxjs'
import Query from './Query'
import Interactions from './Interactions'
import Error from './Error'
import {map} from 'rxjs/operators'
import {createStateContext} from '@zambezi/caballo-vivo'

const root = ReactDOM.createRoot(document.getElementById('root'))
const render$ = new Subject().pipe(map(createStateContext(toView)))
render$.subscribe((jsx) => root.render(jsx))

export default render$

function toView(snap) {
  const {error} = snap

  return (
    <React.StrictMode>
      {error ? (
        <Error error={error} />
      ) : (
        <>
          <Interactions />
          <Query />
        </>
      )}
      <p><a href="/">↻</a></p>
    </React.StrictMode>
  )
}
