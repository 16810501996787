import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  connectFirestoreEmulator,
} from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

import { flog, shareLast } from '@zambezi/caballo-vivo'
import { of } from 'rxjs'

const isLocal = window.location.hostname === 'localhost'

const firebaseConfig = {
  apiKey: 'AIzaSyCO216mgILKLpFDLziA3C9NLrFLcbChWSY',
  authDomain: 'orate-dev.firebaseapp.com',
  projectId: 'orate-dev',
  storageBucket: 'orate-dev.appspot.com',
  messagingSenderId: '5319783605',
  appId: '1:5319783605:web:0acd2055a7a1908d14bafc',
  measurementId: 'G-QHSNJVJ7F0',
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const functions = getFunctions(app)

if (isLocal) {
  console.log('Connecting to emulator firestore')
  connectFirestoreEmulator(db, 'localhost', 8082)
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

export const app$ = of(app).pipe(flog('App'), shareLast)
export const db$ = of(db).pipe(flog('DB'), shareLast)
export const functions$ = of(functions).pipe(flog('Funcs'), shareLast)
