import classnames from 'classnames'
import { broadcastQuery$ } from './intents'
import { useState, useRef, useEffect } from 'react'
import styles from './Query.module.scss'
import { useStateContext } from '@zambezi/caballo-vivo'

export default function Query() {
  const { conversation, loader, centralQuery } = useStateContext()
  const [query, setQuery] = useState(centralQuery)
  const boxRef = useRef(null)

  const {id, chatHistory} = conversation || {}

  useEffect(
    () => {
      if (!chatHistory) return
      setQuery('')
    },
    [chatHistory]
  )

  if (!conversation) return <>‡…</>

  return (
    <>
      <div
        className={classnames(styles.card, { [styles.isLoading]: !!loader })}
      >
        <label>
          Q/
          <textarea
            className={styles.area}
            ref={boxRef}
            rows={5}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyPress={submitOnCtrlEnter}
            disabled={!!loader}
          />
          <button
            className={styles.send}
            onClick={() => {
              broadcastQuery$.next({query, id})
              boxRef.current.focus()
            }}
            disabled={!/\w/.test(query) || !!loader}
          >
            Ask
          </button>
        </label>
      </div>
    </>
  )
  function submitOnCtrlEnter(event) {
    if (event.key !== 'Enter') return
    if (!event.ctrlKey) return
    broadcastQuery$.next({query, id})
  }
}
