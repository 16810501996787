import classnames from 'classnames'
import {useStateContext} from '@zambezi/caballo-vivo'
import styles from './Interactions.module.scss'

export default function Interactions() {
  const {conversation} = useStateContext()

  if (!conversation) return <>‡…</>

  const roleToLabel = {
    user: 'Q/',
    agent: 'A/',
  }

  const interactions = conversation.interactions || []

  return (
    <>
      <section>
        {interactions.map((interaction, i) => {
          const {role, text, references} = interaction
          console.log('references', references)

          const indexedRefs = (references || []).reduce(byLocation, {})
          const refItems = Object.keys(indexedRefs).map((location) => ({
            location,
            title: indexedRefs[location],
          }))

          console.table(refItems)

          return (
            <div
              className={classnames(styles.interaction, styles[role])}
              key={`${i}-${role}`}
            >
              <p>{roleToLabel[role]}</p>
              <p className={styles.text}>{strip(text)}</p>
              {refItems.length? (
                <p className={styles.seeAlso}>
                  {'Ref: '}
                  {refItems.map(({location, title}, i) => {
                    return (
                      <span>
                        <a
                          className={styles.ref}
                          href={location}
                          target="ref"
                          key={location}
                          data-tooltip={title}
                        >
                          [{i}]
                        </a>{' '}
                      </span>
                    )
                  })}
                </p>
              ) : ''}
            </div>
          )
        })}
      </section>
    </>
  )
}

function strip(str) {
  return str.replace(/^\s+|\s+$/g, '')
}

function byLocation(acc, next) {
  acc[next.location] = next.title
  return acc
}
