import {merge, of} from 'rxjs'
import {scan, catchError, startWith} from 'rxjs/operators'
import {flog} from '@zambezi/caballo-vivo'
import location$ from './location'
import {queryBroadcast$, conversation$} from './conversation'
import loader$ from './loader'
import stow from './stow'

const store$ = merge(
  location$.pipe(stow('location')),
  loader$.pipe(stow('loader')),
  queryBroadcast$.pipe(stow('centralQuery')),
  conversation$.pipe(stow('conversation')),
).pipe(
  catchError((error) =>
    of((state) => {
      return {...state, error}
    }),
  ),
  scan((snap, reducer) => {
    return reducer(snap)
  }, {}),
  startWith({up: new Date()}),
  flog('Snapshot'),
)

export default store$
